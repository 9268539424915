import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Holder = styled.div`
  display: none;
   
  @media(${props => props.theme.breakpoints.lg}) {
   width: 100%;
   
   padding: 1.5rem 0; 
   border-top: 0.5px solid;
   
   align-items: start;
   display: grid;
   grid-template-columns: 3fr 3fr 3fr 2fr 3fr 3fr 1fr 1fr 2fr 1fr 2fr;
   grid-column-gap: 2rem;
  }
`;

class JobRow extends Component {
  render() {
    return (
      <Holder header={this.props.header}>{this.props.children}</Holder>
    )
  }
}

JobRow.propTypes = {
  header: PropTypes.bool
};
JobRow.defaultProps = {
  header: false
};

export default JobRow;
