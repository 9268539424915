import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

class Image extends Component {
  render() {
    const { imgName, style, imgStyle, placeholderStyle } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query {
            allImageSharp {
              edges {
                node {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                    originalName
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const image = data.allImageSharp.edges.find(
            edge => edge.node.fluid.originalName === imgName.replace( /^.*[\\/]/, '' )
          );
          if ( !image ) {
            return null
          }
          return <Img
            style={style}
            imgStyle={imgStyle}
            placeholderStyle={placeholderStyle}
            fluid={image.node.fluid}/>
        }}
      />
    )
  }
}

Image.propTypes = {
  imgName: PropTypes.string,
  style: PropTypes.object,
  imgStyle: PropTypes.object,
  placeholderStyle: PropTypes.object,
};

export default Image;
