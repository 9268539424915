import React, { Component } from 'react';
import styled from 'styled-components';
import Image from '../atoms/Image';
import PropTypes from 'prop-types';

const Holder = styled.div`
  cursor: pointer;
  justify-self: end;
  display: flex;
  justify-content: end;
`;

const ImageHolder = styled.div`
  width: 6rem;
  max-width: 100%;
`;

class JobRowImageButton extends Component {

  clickHandler = ( e ) => {
    this.props.handleClick( e );
  };

  render() {
    if ( this.props.image ) {
      return (
        <Holder onClick={( e ) => {this.clickHandler( e )}}>
          <ImageHolder><Image imgName={this.props.image}/></ImageHolder>
        </Holder>
      )
    } else {
      return null;
    }
  }
}

JobRowImageButton.propTypes = {
  image: PropTypes.string
};

export default JobRowImageButton;
