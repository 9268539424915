import React, { Component } from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';

const Section = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 6rem;
  grid-row-gap: 2rem;
  margin-bottom: 6rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 1fr 1fr;
  }
  h2 {
    ${props => props.theme.typeStyles( 0 )};
  }
  p, li {
    ${props => props.theme.typeStyles( 3 )};
    @media ( ${props => props.theme.breakpoints.sm} ) {
      ${props => props.theme.typeStyles( 4 )};
    }
  }
`;

class About extends Component {
  render() {
    return (
      <Container>
        <Section>

          <div>
            <h2>About the studio</h2>
            <ul>
              <li><em>Will's Websites</em> is a design and web development studio run
                by <a href="https://www.linkedin.com/in/william-mclean-aba47b110" target="_blank" rel="noopener noreferrer">Will
                  McLean</a>.
              </li>
              <li>We are based in <a href="https://www.google.com/maps/place/Berwick,+Polegate+BN26+6SW/@50.8241381,0.1430418,15z/data=!3m1!4b1!4m5!3m4!1s0x47df7ab3e1451db1:0xa299235b4c11fec2!8m2!3d50.824139!4d0.151818" target="_blank" rel="noopener noreferrer"> Berwick, East Sussex, UK</a></li>
            </ul>
          </div>

          <div>
            <h2>About the service</h2>
            <ul>
              <li>We provide boutique websites.</li>
              <li>We work with many designers. Some of our favourite people to work with
                are <a href="http://craigwalker.com.au/" target="_blank" rel="noopener noreferrer">Craig
                  Walker</a>, <a href="http://alwaysstudio.com/" target="_blank" rel="noopener noreferrer">Always
                  Studio</a>, <a href="https://patarmstrong.net.au/" target="_blank" rel="noopener noreferrer">Pat
                  Armstrong</a> or <a href="https://www.studio-days.com/" target="_blank" rel="noopener noreferrer">Michael
                  Inglis</a>.
              </li>
              <li>We are always happy to work on projects with other designers or studios.</li>
            </ul>
          </div>

          <div>
            <h2>About the pricing & workflow</h2>
            <ul>
              <li>In most cases our websites fall somewhere between $4000 and $8000.</li>
              <li>In most cases our websites take somewhere between 5 and 10 days.</li>
              <li>We quote and charge in blocks of time rather than providing a cost per feature,
                allowing for more
                flexibility in the brief and solution.
              </li>
              <li>With every website you get a <em>free</em> hat</li>
            </ul>
          </div>

          <div>
            <h2>About the technology</h2>
            <ul>
              <li>We are
                a <a href="https://jamstack.org/" target="_blank" rel="noopener noreferrer">JAMstack</a> studio.
              </li>
              <li>All our sites
                use <a href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer">Gatsby</a>,
                a <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React</a> based <a href="https://davidwalsh.name/introduction-static-site-generators" target="_blank" rel="noopener noreferrer">Static
                  Site Generator</a>.
              </li>
              <li>As a CMS for small sites we
                use <a href="https://www.netlifycms.org/" target="_blank" rel="noopener noreferrer">Netlify
                  CMS</a>.
              </li>
              <li>As a CMS for medium to large size sites we
                use <a href="https://www.contentful.com/" target="_blank" rel="noopener noreferrer">Contentful</a>.
              </li>
              <li>We can work with your preferred choice
                of <a href="https://headlesscms.org/" target="_blank" rel="noopener noreferrer">headless
                  CMS</a>, bearing in mind some are better suited to Gatsby than others.
              </li>
              <li>We host our sites for free
                on <a href="https://www.netlify.com/" target="_blank" rel="noopener noreferrer">Netlify</a>
              </li>
              <li>We can takeover legacy code and will develop a plan for this upon request.</li>
              <li>In very rare cases we can work
                with <a href="https://wordpress.org/" target="_blank" rel="noopener noreferrer">WordPress</a>.
              </li>
            </ul>
          </div>

        </Section>
      </Container>
    )
  }
}

export default About;
