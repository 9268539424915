import React, { Component } from 'react';
import styled, {withTheme} from 'styled-components';
import PropTypes from 'prop-types';

const Dot = styled.span`
  width: 1rem;
  height: 1rem;
  display: inline-block;
  border-radius: 50%;
  background-color: ${props => props.colour};
  margin-right: 0.5rem;
`;

class Status extends Component {

  state = {
    colour: null,
  };

  componentDidMount() {
    let colour;
    let {theme} = this.props;
    if ( this.props.status === 'Complete' ) {
      colour = theme.colours.complete;
    } else if ( this.props.status === 'Live' ) {
      colour = theme.colours.live;
    } else if ( this.props.status === 'Superseded' ) {
      colour = theme.colours.superseded;
    } else {
      colour = theme.colours.inProgress;
    }
    this.setState( {
      colour: colour,
    } )
  }

  render() {
    return (
      <p><Dot colour={this.state.colour}/>{this.props.status}</p>
    )
  }
}

Status.propTypes = {
  status: PropTypes.string
};

export default withTheme(Status);
