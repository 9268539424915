import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Status from '../atoms/Status';
import JobRow from './JobRow';
import moment from 'moment';
import MarkdownConverter from '../atoms/MarkdownConverter';
import JobRowImageButton from './JobRowImageButton';
import JobRowImages from './JobRowImages';

const Cell = styled.div`
  display: none;
  @media(${props => props.theme.breakpoints.lg}) {
    display: block;
  }
  p {
    margin: 0; 
    line-height: 2rem;
    text-align: left;
    ${props => props.large ? props.theme.typeStyles( -2 ) : props.theme.typeStyles( -3 )};
    font-style: ${props => props.large ? 'italic' : 'normal'};
  }
`;

const Days = styled.span`
  ${props => props.theme.typeStyles( -6 )}; 
`;

function LargeJob( { frontmatter } ) {

  const [images, setImages] = useState([]);
  const [showVisuals, setShowVisuals] = useState(false);

  useEffect(() => {
    if ( frontmatter.thumbnail ) {
      let newState = [ frontmatter.thumbnail.replace( '/images/uploads/', '' ) ];
      if ( frontmatter.thumbnail2 ) { newState.push( frontmatter.thumbnail2.replace( '/images/uploads/', '' ) ) }
      if ( frontmatter.thumbnail3 ) { newState.push( frontmatter.thumbnail3.replace( '/images/uploads/', '' ) ) }
      setImages(newState);
    }
  }, [frontmatter.thumbnail, frontmatter.thumbnail2, frontmatter.thumbnail3]);

  return (
    <JobRow>
      <Cell large><p>{frontmatter.title ? frontmatter.title : '-'}</p></Cell>
      <Cell large><p>{frontmatter.client ? frontmatter.client : '-'}</p></Cell>
      <Cell large><p>{frontmatter.clientsClient ? frontmatter.clientsClient : '-'}</p></Cell>
      <Cell><Status status={frontmatter.status}/></Cell>
      <Cell>
        {!frontmatter.url && <p>{frontmatter.task}</p>}
        {frontmatter.url && <p><a href={frontmatter.url} target="_blank" rel="noopener noreferrer">{frontmatter.task}</a></p>}
      </Cell>
      <Cell><p>{frontmatter.tech}</p></Cell>
      <Cell><p>{moment( frontmatter.date ).format( 'YYYY' )}</p></Cell>
      <Cell><p>{frontmatter.cost}</p></Cell>
      <Cell>{frontmatter.design ? <MarkdownConverter content={frontmatter.design}/> : '-'}</Cell>
      <Cell><p>{frontmatter.turnaround}<Days> days</Days></p></Cell>
      <Cell><JobRowImageButton image={images[ 0 ]} handleClick={() => {setShowVisuals( !showVisuals );}}/></Cell>
      <JobRowImages show={showVisuals} images={images}/>
    </JobRow>
  )
}

LargeJob.propTypes = {
  frontmatter: PropTypes.object.isRequired,
};

export default LargeJob;
