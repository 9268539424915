import React, { Component } from 'react';
import MobileJob from './MobileJob';
import LargeJob from './LargeJob';


class Job extends Component {

  render() {
    const { frontmatter } = this.props.post;
    return (
      <>

        <LargeJob frontmatter={frontmatter}/>
        <MobileJob frontmatter={frontmatter}/>
      </>
    )
  }
}

export default Job;
