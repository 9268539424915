import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Status from '../atoms/Status';

const Holder = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid;
  @media( ${props => props.theme.breakpoints.sm} ) {
    display: grid;
    grid-template-columns: 3fr 1fr;
  }
  @media( ${props => props.theme.breakpoints.lg} ) {
    display: none;
  }
  p { 
    margin: 0; 
    ${props => props.theme.typeStyles( -2 )};
  }
`;

const Days = styled.span`
  ${props => props.theme.typeStyles( -6 )}; 
`;

function MobileJob( { frontmatter } ) {
  return (
    <Holder>
      <div>
        {!frontmatter.url && <p>&#9733;
          <em>{frontmatter.task}</em> for {frontmatter.client}{frontmatter.clientsClient && ` & ${frontmatter.clientsClient}`}
        </p>}
        {frontmatter.url && <p>&#9733;
          <em><a href={frontmatter.url} target="_blank" rel="noopener noreferrer">{frontmatter.task}</a></em> for {frontmatter.client}{frontmatter.clientsClient && ` & ${frontmatter.clientsClient}`}
        </p>}
        <p>{frontmatter.tech}{frontmatter.turnaround && <> &#124; {frontmatter.turnaround}<Days> days</Days></>}{frontmatter.cost && `, ${frontmatter.cost}`}</p>
      </div>
      <Status status={frontmatter.status}/>
    </Holder>
  )
}

MobileJob.propTypes = {
  frontmatter: PropTypes.object.isRequired,
};

export default MobileJob;
