import React, { Component } from 'react';
import Layout from '../components/organisms/Layout';
import SEO from '../components/molecules/SEO';
import About from '../components/molecules/About';
import JobList from '../components/organisms/JobList';
import FreeHat from '../components/molecules/FreeHat';
import VideoGrid from '../components/organisms/VideoGrid';

class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Home" keywords={[ `websites`, `will mclean`, `design` ]}/>
        <article>
          <VideoGrid/>
          <JobList/>
          <FreeHat/>
          <About/>
        </article>
      </Layout>
    )
  }
}

export default IndexPage;
