import React, { Component } from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import Image from '../atoms/Image';
import classNames from 'classnames';

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media(${props => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
  }
  h2 {
    text-transform: uppercase;
    font-style: normal;
    ${props => props.theme.typeStyles( 10 )};
    margin: 0;
    line-height: 0.9;
    text-align: justify;
    @media(${props => props.theme.breakpoints.sm}) {
    text-align: left;
      ${props => props.theme.typeStyles( 12 )};
    }
    @media(${props => props.theme.breakpoints.md}) {
      ${props => props.theme.typeStyles( 14 )};
    }
    @media(${props => props.theme.breakpoints.lg}) {
      ${props => props.theme.typeStyles( 16 )};
    }
    @media(${props => props.theme.breakpoints.xl}) {
      ${props => props.theme.typeStyles( 17 )};
    }
  }
`;
const ImageHolder = styled.div`
  display: none;
  width: 100%;
  &.active {
    display: block;
  }
`;

const hats = [
  'floraHat.png',
  'hat-3.png',
  'hat-2.png'
];

class FreeHat extends Component {

  state = {
    hatIndex: 0,
    intervalId: null
  };

  componentDidMount = () => {
     let intervalId = setInterval(this.moveNext, 2000);
     // store intervalId in the state so it can be accessed later:
     this.setState({intervalId: intervalId});
  };

  componentWillUnmount = () => {
     // use intervalId from the state to clear the interval
     clearInterval(this.state.intervalId);
  };

  moveNext = () => {
    if ( this.state.hatIndex === hats.length - 1 ) {
      this.setState( {
        hatIndex: 0,
      } );
    } else {
      this.setState( {
        hatIndex: this.state.hatIndex + 1,
      } );
    }
  };

  render() {
    return (
      <Container>
        <Holder>
          <h2><em>Free</em> hat with every website</h2>
          {hats.map( ( image, i ) => {
            let classes = classNames( { 'active': this.state.hatIndex === i } );
            return (
              <ImageHolder className={classes} key={i}>
                <Image
                  imgName={image}
                  imgStyle={{
                    mixBlendMode: 'screen'
                  }}/>
              </ImageHolder>
            )
          } )}
        </Holder>
      </Container>
    )
  }
}

export default FreeHat;
