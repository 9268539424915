import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Job from '../molecules/Job';
import Container from '../atoms/Container';

const Holder = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  align-items: start;
  @media(${props => props.theme.breakpoints.sm}) {
    margin: 8rem 0;
  }
  @media(${props => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
  > h2 {
    margin-top: 0;
  }
`;

const Header = styled.div`
  display: none;
   
  @media(${props => props.theme.breakpoints.lg}) {
   width: 100%;
   
   position: sticky;
   top: 0;
   z-index: 10;
   background-color: ${props => props.theme.colours.black};
   
   padding: 0.75rem 0;
   border-bottom: 1px solid;
   
   align-items: start;
   display: grid;
   grid-template-columns: 3fr 3fr 3fr 2fr 3fr 3fr 1fr 1fr 2fr 1fr 2fr;
   grid-column-gap: 2rem;
  }
  p {
    font-style: italic;
    ${props => props.theme.typeStyles( -4 )}; 
    margin: 0;
    @media(${props => props.theme.breakpoints.lg}) {
      display: block;
    }
    &:last-child {
      justify-self: end;
    }
  }
`;

class JobList extends Component {

  render() {
    return (
      <Container>
        <Holder>
          <h2>Job List</h2>
          <Header>
            <p>Title</p>
            <p>Client</p>
            <p>Client's Client</p>
            <p>Status</p>
            <p>Task</p>
            <p>Technology</p>
            <p>Year</p>
            <p>Cost</p>
            <p>Design</p>
            <p>Duration</p>
            <p>Visuals</p>
          </Header>

          <StaticQuery
            query={graphql`
            query JobListQuery {
            allMarkdownRemark(
                filter: {
                    fileAbsolutePath: {regex : "\/_posts/websites/"}
                }
                sort: {
                    fields: [frontmatter___date]
                    order: DESC
                }
            ){
                edges {
                    node{
                        id
                        html
                        frontmatter {
                            title
                            date
                            client
                            clientsClient
                            task
                            status
                            design
                            cost
                            thumbnail
                            thumbnail2
                            thumbnail3
                            url
                            tech
                            turnaround
                        }
                    }
                }
            }
          }`}
            render={data => {
              return (<>{data.allMarkdownRemark.edges.map( ( edge, i ) => (
                <Job key={edge.node.id} postNo={i} post={edge.node}/>
              ) )}</>)
            }}/>

        </Holder>
      </Container>
    )
  }
}

export default JobList;
