import React, { Component } from 'react';
import showdown from 'showdown'

const converter = new showdown.Converter( { simpleLineBreaks: true } );

class MarkdownConverter extends Component {
  render() {
    if ( this.props.content ) {
      return (
        <div dangerouslySetInnerHTML={{ __html: converter.makeHtml( this.props.content ) }}/>
      )
    } else {
      return null;
    }
  }
}

export default MarkdownConverter;
