import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Image from '../atoms/Image';

const StaticImages = styled.div`
  display: none;
  @media(${props => props.theme.breakpoints.lg}) {
    width: 100%;
    display: grid;
    grid-column: span 11;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    margin-top: ${props => props.show ? '2rem' : 0};
    max-height: ${props => props.show ? '50vh' : 0};
    transition: max-height 0.25s, margin 0.25s;
    overflow: hidden;
  }
`;

const ImageHolder = styled.div`
  width: 100%;
`;

class JobRowImages extends Component {

  render() {
    if ( this.props.images[ 0 ] ) {
      return (
        <StaticImages show={this.props.show}>
          {this.props.images.map( ( image, i ) => {
            return (
              <ImageHolder key={i}>
                <Image imgName={image}/>
              </ImageHolder>
            )
          } )}
        </StaticImages>
      )
    } else {
      return null;
    }
  }
}

JobRowImages.propTypes = {
  images: PropTypes.array,
  show: PropTypes.bool
};

export default JobRowImages;
