import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Video from '../atoms/Video';
import Container from '../atoms/Container';

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  @media( ${props => props.theme.breakpoints.sm} ) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

function VideoGrid() {
  return (
    <StaticQuery
      query={graphql`
            query VideoGridQuery {
              full: allFile(
                filter: {sourceInstanceName: {eq: "videos"}},
                sort: {order: ASC, fields: name}
              ) {
                nodes {
                  id
                  publicURL
                }
              }
          }`}
      render={data => (
        <Container>
          <Holder>
            {data.full.nodes.map( node =>
              <Video key={node.id} videoSrc={node.publicURL}/>
            )}
          </Holder>
        </Container>
      )}/>
  )
}

export default VideoGrid;
