import React from 'react';
import PropTypes from 'prop-types';
import EmbedContainer from './EmbedContainer';

function Video( { videoSrc } ) {
  return (
    <EmbedContainer>
      <video
        autoPlay
        muted
        loop
        playsInline
        controls={false}>
        <source src={videoSrc} type="video/mp4"/>
      </video>
    </EmbedContainer>
  )
}

Video.propTypes = {
  videoSrc: PropTypes.string.isRequired,
};

export default Video;
